import { useState, useEffect } from "react";
import { Box, Button, Stack, Typography, Tooltip, } from '@mui/material';
import { Badge, Dialog, DialogActions, ToggleButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { fileStore } from "../utils/localforageInstances";

import FilePreview from "./FilePreview";

import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import AllInboxIcon from '@mui/icons-material/AllInbox';

export default function P2PReceivedFiles({ filter = { from: "", fileCount: 0 } }) {
    const [receivedFiles, setReceivedFiles] = useState([])
    const [refresh, setRefresh] = useState(true)
    const [openReceivedFiles, setOpenReceivedFiles] = useState(false)
    const theme = useTheme()
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));

    const files = receivedFiles
        .filter((item, index) => receivedFiles.indexOf(item) === index)
        .sort((a, b) => a.arrivedAt < b.arrivedAt)
        .map((x, index) => (
            <Stack key={x.file.name + index} spacing={0}>
                <FilePreview file={Object.assign(x.file, { preview: URL.createObjectURL(x.file), from: x?.from, to: x?.to })} />
            </Stack>
        ));

    useEffect(() => {
        if (openReceivedFiles) {
            let temp = []
            fileStore.iterate(function (value, key, iterationNumber) {
                // console.log([key, value]);
                if (filter?.from) {
                    if (value?.from === filter?.from) {
                        temp = [...temp, value]
                    }
                } else {
                    temp = [...temp, value]
                }
            }).then(function () {
                // console.log('Iteration has completed');
                setReceivedFiles(temp)
                temp = []
                setRefresh(false)
            }).catch(function (err) {
                console.log(err);
                setRefresh(false)
            });
        }
    }, [openReceivedFiles, filter?.from, refresh])

    const deleteFilesFrom = (filter) => {
        fileStore.iterate(function (value, key, iterationNumber) {
            // console.log([key, value]);
            if (filter?.from) {
                if (value?.from === filter?.from) {
                    fileStore.removeItem(key).then(res => {
                        console.log("deleted")
                    }).catch(err => console.log("not deleted", err))
                }
            } else {
                fileStore.clear()
            }
        }).then(function () {
            // console.log('Iteration has completed');
            setRefresh(true)
        }).catch(function (err) {
            console.log(err);
            setRefresh(false)
        });
    }

    return (
        <Box>
            <Tooltip title={"Files Received"}>
                <ToggleButton value={"received_files"} selected={openReceivedFiles} size="small" onClick={() => setOpenReceivedFiles(true)} label={"Show Received Files"} color="info" sx={{ textTransform: "none", borderRadius: 2, ...(!smScreen && { px: 1 }) }} >
                    {/* <Badge badgeContent={receivedFiles.length} color="error" anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}> */}
                    <AllInboxIcon />
                    {/* </Badge> */}
                    {!smScreen && <Typography sx={{ ml: 1 }} variant="caption">File Inbox</Typography>}
                </ToggleButton>
            </Tooltip>

            <Dialog open={openReceivedFiles} onClose={() => setOpenReceivedFiles(false)} sx={{ mb: { xs: 6, sm: 6, md: 0, lg: 0 } }} PaperProps={{ sx: { borderRadius: 3 } }} fullWidth>
                <Stack spacing={2} sx={{ p: 2 }}>
                    <Typography variant="subtitle1" fontWeight={"bold"}>
                        Received file list • {files.length} items
                    </Typography>
                    {receivedFiles && receivedFiles.length > 0 && <Stack maxHeight={500} spacing={0} sx={{ border: 1, borderRadius: 3, borderColor: "divider", overflow: "auto", scrollbarWidth: "thin" }}>
                        {files}
                        <Button startIcon={<DeleteTwoToneIcon />} onClick={() => { receivedFiles.splice(0, receivedFiles.length); deleteFilesFrom(filter); }} color='error' sx={{ p: 2 }}>CLEAR RECEIVED FILE(S)</Button>
                    </Stack>}
                    {receivedFiles && receivedFiles.length === 0 && <Stack minHeight={100} alignItems={"center"} justifyContent={"center"}>
                        <Typography variant="subtitle2" fontWeight={"bold"} color={"text.disabled"} align="center">
                            Received file list is empty!
                        </Typography>
                    </Stack>}
                </Stack>
                <DialogActions>
                    <Button onClick={() => setOpenReceivedFiles(false)} color="error" sx={{ p: 2 }} fullWidth>CLOSE</Button>
                </DialogActions>
            </Dialog>
        </Box >
    )
}