import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/WebGLTile';
import OSM from 'ol/source/OSM';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Overlay } from 'ol';
import { fromLonLat, toLonLat } from 'ol/proj.js';
import { toStringHDMS } from 'ol/coordinate.js';
import Draw from 'ol/interaction/Draw.js';
import { FullScreen, defaults as defaultControls } from 'ol/control.js';
import Graticule from 'ol/layer/Graticule.js';
import Link from 'ol/interaction/Link.js';
import { Icon, Style, Text, Fill, Stroke } from 'ol/style';

import { Typography, Card, CardMedia, Divider, Stack, ListItem, ListItemText, Avatar, ListItemAvatar } from '@mui/material';

import { mypeer } from '../utils/mypeer';
import { socket } from '../utils/socket';

import { useTheme } from '@mui/material/styles';

import DevicesIcon from '@mui/icons-material/Devices';

const LiveLocationMap = ({ id = "map", maxHeight, startLocation = [78.9629, 20.5937] }) => {
    const theme = useTheme()
    const [markerData, setMarkerData] = useState([]);
    const [nicknames, setNicknames] = useState({})

    // Ref for the vector layer that contains the markers 
    const vectorLayerRef = useRef();

    let draw; // global so we can remove it later 
    function addInteraction(map, drawSource, type = "Circle") {
        const value = type;
        if (value !== 'None') {
            draw = new Draw({
                source: drawSource,
                type: type,
                freehand: true,
            });
            map.addInteraction(draw);
        }
    }

    useEffect(() => {
        // Create a vector layer for the markers 
        const vectorSource = new VectorSource();
        const vectorLayer = new VectorLayer({
            source: vectorSource,
        });
        vectorLayerRef.current = vectorLayer;

        const drawSource = new VectorSource({ wrapX: false });
        const drawVector = new VectorLayer({
            source: drawSource,
        });

        const graticuleLines = new Graticule({
            // the style to use for the lines, optional. 
            strokeStyle: new Stroke({
                color: '#bdbdbd',
                width: 1,
                lineDash: [0.5, 4],
            }),
            showLabels: false,
            wrapX: false,
        })

        // Create a map with the OpenLayers library 
        const map = new Map({
            controls: defaultControls().extend([new FullScreen()]),
            target: id,
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
                vectorLayer,
                drawVector,
                // graticuleLines,
            ],
            view: new View({
                center: fromLonLat(startLocation), // [lat, lon]
                zoom: 3,
            }),
        });

        // map.addInteraction(new Link());

        // dark mode map 
        // map.on('postcompose', function (e) {
        //     document.querySelector('canvas').style.filter = ({ dark: "grayscale(80%) invert(100%) ", light: "" }[theme.palette.mode]);
        // });

        // addInteraction(map, drawSource, ["LineString", "Polygon", "Circle", "None"][3])

        // Add a mouseover (pointermove) event listener to the map 
        map.on('click' || 'pointermove', (event) => {
            const feature = map.forEachFeatureAtPixel(event.pixel, (feature) => feature);
            if (feature && feature.get('overlay')) {
                // console.log(feature?.id_) 
                const overlay = feature.get('overlay');
                overlay.setPosition(event.coordinate);
                map.addOverlay(overlay)
            } else {
                const overlays = map.getOverlays().getArray();
                overlays.forEach((overlay) => { overlay.setPosition(undefined); map.removeOverlay(overlay) });
            }
        });

        // map.on("dblclick", (event) => { 
        //     map.removeInteraction(draw) 
        // }) 

        // map.on('click', function (evt) {
        //     const coordinate = evt.coordinate;
        //     const hdms = toStringHDMS(toLonLat(coordinate));
        //     console.log(hdms)
        //     console.table(coordinate);

        // });

        if (id === "map") {
            socket.on("liveLocations", (data) => {
                // console.log("liveLocations:", data)
                let liveLocations = Object.keys(data)
                    .filter(key => data[key]?.timestamp && (new Date().getTime() - new Date(data[key]?.timestamp).getTime()) < 5 * 60 * 1000 && key) // 5 min filter
                    .filter(key => data[key]?.peerId && data[key]?.latitude && data[key]?.longitude && key)
                    .map(key => ({
                        id: data[key]?.peerId,
                        lat: data[key]?.latitude,
                        lng: data[key]?.longitude,
                    }))
                // console.log("liveLocations:", liveLocations)
                setMarkerData(liveLocations)
            })

            socket.on("nicknames", (nicknames) => {
                setNicknames(nicknames)
            })
        }

        return () => {
            // Clean up the map when the component unmounts 
            map.setTarget(null);
            socket.off("liveLocations");
            socket.off("nicknames");
        };
    }, []);

    useEffect(() => {
        // Update the vector layer with the new marker data 
        if (vectorLayerRef.current && markerData.length) {
            const markers = markerData.map((marker) => {
                const { id, lat, lng } = marker;
                const markerFeature = new Feature({
                    geometry: new Point(fromLonLat([lng, lat])),
                });

                markerFeature.setStyle(new Style({
                    image: new Icon({
                        crossOrigin: 'anonymous',
                        src: mypeer?._id === id ? 'pos_me.svg' : 'pos_anonymous.svg',
                        scale: 0.025,
                    })
                }))

                // Create an overlay for the tooltip 
                const overlay = new Overlay({
                    element: document.createElement('div'),
                    offset: [5, 0],
                    positioning: 'bottom-left',
                });
                overlay.getElement().textContent = id;
                overlay.getElement().innerHTML = "";
                const tooltipContent = (
                    <Card id={id} variant='outlined' sx={{ borderRadius: 3 }}>
                        <ListItem variant="outlined" key={id}>
                            <ListItemAvatar>
                                <Avatar src={nicknames?.[id]?.dp ? nicknames?.[id]?.dp : ""} variant="rounded" sx={{ background: "transparent" }}>
                                    <DevicesIcon color={nicknames?.[id]?.nickname ? "success" : "secondary"} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={nicknames?.[id]?.nickname ? nicknames?.[id]?.nickname : "Anonymous"} secondary={id} />
                        </ListItem>
                        <Divider />
                        <Typography sx={{ my: 1 }} align='center' variant="subtitle2" fontWeight={"light"} color={"text.secondary"}>Latitude: {marker?.lat}, Longitude: {marker?.lng}</Typography>
                    </Card>
                )
                ReactDOM.createRoot(overlay.getElement()).render(tooltipContent);
                markerFeature.set('overlay', overlay);

                markerFeature.setId(id);
                return markerFeature;
            });

            const vectorSource = vectorLayerRef.current.getSource();
            vectorSource.clear();
            vectorSource.addFeatures(markers);
        }
    }, [markerData, nicknames]);

    return (
        <CardMedia component={"div"} id={id} style={{ width: '100%', height: maxHeight ? maxHeight : `${window.innerHeight}px` }} />
    );
};

export default LiveLocationMap;
