import React, { useState, useEffect, useRef } from "react";
import { Alert, Badge, Box, Button, Divider, IconButton, Stack, Card, Tooltip, CardMedia, CardActions, Typography, List, ListItem, ListItemText, Container, Grid, ListItemAvatar, Avatar, TextField, Link, Dialog, DialogContent, DialogActions, Chip, ListItemIcon } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';

import { Geolocation } from '@capacitor/geolocation';
import { formatDistanceToNow, format } from "date-fns";
import objectHash from "object-hash";
import { Howl } from "howler";

import { mypeer } from "../utils/mypeer";
import { socket } from "../utils/socket";
import { distance } from "../utils/distance";
import { isIsoDateString, formatBytes } from "../utils/common";
import { fileStore } from "../utils/localforageInstances";

import P2PReceivedFiles from "./P2PReceivedFiles";

import VideoChatIcon from '@mui/icons-material/VideoChat';
import CallIcon from '@mui/icons-material/Call';
import CallEndIcon from '@mui/icons-material/CallEnd';
import DevicesIcon from '@mui/icons-material/Devices';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import ReportProblemTwoToneIcon from '@mui/icons-material/ReportProblemTwoTone';
import PublishedWithChangesRoundedIcon from '@mui/icons-material/PublishedWithChangesRounded';
import UnpublishedRoundedIcon from '@mui/icons-material/UnpublishedRounded';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
// import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded';
// import VideocamIcon from '@mui/icons-material/Videocam';
// import VideocamOffIcon from '@mui/icons-material/VideocamOff';
// import MicIcon from '@mui/icons-material/Mic';
// import MicOffIcon from '@mui/icons-material/MicOff';

import LiveLocationMap from "./LiveLocationMap";

import dialtone from '../assets/audio/dialertone.mp3';
import ringtone from '../assets/audio/office_phone.mp3';
import notify from '../assets/audio/messagetone.mp3';

const constraints = {
    audio: true,
    video: {
        width: { exact: 320 },
        height: { exact: 240 },
        frameRate: 20
    }
}

const incoming_sound = new Howl({
    src: [ringtone],
    autoplay: false,
    onload: () => {
        console.log("Loaded!")
    },
    onend: () => {
        console.log('Finished!');
    }
});

const outgoing_sound = new Howl({
    src: [dialtone],
    autoplay: false,
    onload: () => {
        console.log("Loaded!")
    },
    onend: () => {
        console.log('Finished!');
    }
});

const notify_sound = new Howl({
    src: [notify],
    autoplay: false,
    onload: () => {
        console.log("Loaded!")
    },
    onend: () => {
        console.log('Finished!');
    }
});


const MAX_FILE_SIZE = 1024 * 1024 * 100;

export default function VideoCall() {
    const [myName, setMyName] = useState("")
    const [myDp, setMyDp] = useState("")
    const [peers, setPeers] = useState([])
    const [peerIds, setPeerIds] = useState({})
    const [incomingCalls, setIncomingCalls] = useState({})
    const [outgoingCalls, setOutgoingCalls] = useState({})
    const [incomingConns, setIncomingConns] = useState({})
    const [outgoingConns, setOutgoingConns] = useState({})
    const [myStreamsForIncomingCalls, setMyStreamsForIncomingCalls] = useState({})
    const [myStreamsForOutgoingCalls, setMyStreamsForOutgoingCalls] = useState({})
    const [myCall, setMyCall] = useState()
    const [myStreamForMakeCall, setMyStreamForMakeCall] = useState()
    const [myStreamForAnswerCall, setMyStreamForAnswerCall] = useState()
    const [myLocation, setMyLocation] = useState({})
    const [liveLocations, setLiveLocations] = useState({})
    const [nicknames, setNicknames] = useState({})
    const [warning, setWarning] = useState("")

    const localVideoRef = useRef()
    const remoteVideoRef = useRef()
    const playIncomingSoundRef = useRef()
    const stopIncomingSoundRef = useRef()
    const playOutgoingSoundRef = useRef()
    const stopOutgoingSoundRef = useRef()
    const playNotificationSoundRef = useRef()
    const stopNotificationSoundRef = useRef()

    const theme = useTheme()
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));
    const xsScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const publishNickname = () => {
        if (mypeer?._id) {
            socket.emit('nickname', { peerId: mypeer?._id, nickname: myName, dp: myDp })
        }
    }

    const unPublishNickname = () => {
        if (mypeer?._id) {
            socket.emit('nickname', { peerId: mypeer?._id, nickname: "", dp: "" })
        }
    }

    useEffect(() => {
        console.log("set socket inside useEffect...!")

        socket.on("peers", (newPeers) => {
            setPeers(newPeers)
            // console.log("🌐 PEERS UPDATED!")
        })

        socket.on("liveLocations", (locations) => {
            setLiveLocations(locations)
        })

        socket.on("nicknames", (nicknames) => {
            setNicknames(nicknames)
        })

        // socket.on("peerIds", (newPeerIds) => {
        //     setPeerIds(newPeerIds)
        //     // console.log("🌏 PEER IDS UPDATED!")
        // })

        mypeer.on("open", id => console.log("my peerId: ", id))

        mypeer.on("connection", conn => {
            console.log("new conn: ", conn)
            // setConns(ps => [...ps, conn])
            setIncomingConns(ps => ({ ...ps, [conn?.peer]: conn }))
        })

        mypeer.on("call", incoming_call => {
            try { stopIncomingSoundRef.current.click() } catch (e) { }
            try { playIncomingSoundRef.current.click() } catch (e) { }
            console.log("incoming call: %O", incoming_call)
            setIncomingCalls(ps => ({ ...ps, [incoming_call?.connectionId]: incoming_call }))
        })

        const id = Geolocation.watchPosition({
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0
        }, (response, error) => {
            if (!error && response) {
                // console.log("coordinates: ", response)
                const { accuracy, altitude, altitudeAccuracy, heading, latitude, longitude, speed } = response.coords

                const data = {
                    // ...(myName && { name: myName }),
                    ...(mypeer?._id && { peerId: mypeer?._id }),
                    accuracy: accuracy,
                    altitude: altitude,
                    altitudeAccuracy: altitudeAccuracy,
                    heading: heading,
                    latitude: latitude,
                    longitude: longitude,
                    speed: speed,
                    timestamp: new Date(response.timestamp),
                }

                socket.emit("liveLocation", data)
                setMyLocation(data)
            }
        })

        return () => {
            console.log("free sockets useEffect cleanup...!")
            socket.off("peers")
            socket.off("liveLocations")
            socket.off("nicknames")
            // socket.off("peerIds")
            mypeer.off()
            id && Geolocation.clearWatch(id).catch(error => {
                console.log("clear watch error: ", id, error)
            })
        }
    }, [])

    useEffect(() => {
        console.log("set data incoming conns inside useEffect...!")
        console.log("data conns: ", Object.keys(incomingConns).length)
        Object.keys(incomingConns).map(key => {
            incomingConns[key].on('data', data => {
                console.log('Data Received!');
                const { from, to } = incomingConns[key]?.metadata
                if (data?.file && data?.type) {
                    console.log("FILE IS RECEIVED", data?.name)
                    try { stopNotificationSoundRef.current.click() } catch (e) { }
                    try { playNotificationSoundRef.current.click() } catch (e) { }
                    const blob = new Blob([data.file], { type: data.type });
                    const newFile = new File([blob], data.name, { type: data.type })
                    fileStore.setItem(`${new Date().toISOString()}_${data.name}`, { file: newFile, arrivedAt: new Date().getTime(), from: from, to: to }).then(res => {
                        console.log("res:", res)
                    }).catch((err) => {
                        console.log("err error", err)
                    })
                    // const a = document.createElement("a")
                    // a.href = URL.createObjectURL(newFile);
                    // a.download = data?.name
                    // a.click()
                }
            })

            return key
        })

        return () => {
            console.log("free data incoming conns useEffect cleanup...!")
            Object.keys(incomingConns).map(key => {
                incomingConns[key].off()
                return key
            })
        }
    }, [incomingConns])

    // useEffect(() => {
    //     peers.map(peer => {
    //         const conn = mypeer.connect(peer)
    //         setOutgoingConns(ps => ({ ...ps, [conn?.peer]: conn }))

    //         return peer
    //     })
    // }, [peers])

    // useEffect(() => {
    //     return () => {
    //         console.log("free data outgoing conns useEffect cleanup...!")
    //         Object.keys(outgoingConns).map(key => {
    //             outgoingConns[key].off()
    //             return key
    //         })
    //     }
    // }, [outgoingConns])

    const makeCall = (id, video = true) => {
        myStreamForMakeCall?.getTracks().forEach(function (track) {
            track.stop();
        });

        try {
            const newConstraints = {
                ...constraints, ...(!video && {
                    video: false
                })
            }
            const outgoingCallVideoElement = document.getElementById(`OUTGOING_CALL_REMOTE_STREAM_${id}`)
            const outgoingCallLocalVideoElement = document.getElementById(`OUTGOING_CALL_LOCAL_STREAM_${id}`)
            navigator?.mediaDevices && navigator.mediaDevices.getUserMedia(newConstraints)
                .then((stream) => {
                    // setMyStreamForMakeCall(stream)
                    // localVideoRef.current.srcObject = stream;
                    outgoingCallLocalVideoElement.srcObject = stream;
                    setMyStreamsForOutgoingCalls(ps => ({ ...ps, [id]: stream }))
                    const madeAt = new Date().toISOString()
                    var call = mypeer.call(id, stream, { metadata: { video: video, nickname: myName ? myName : "Anonymous", madeAt: madeAt, location: myLocation } })
                    // setMyCall(call)
                    try { playOutgoingSoundRef.current.click() } catch (e) { }
                    setOutgoingCalls(ps => ({ ...ps, [id]: call }))
                    call.on('stream', function (stream) {
                        console.log("outgoing call accepted!")
                        try { stopOutgoingSoundRef.current.click() } catch (e) { }
                        // remoteVideoRef.current.srcObject = stream;
                        if (outgoingCallVideoElement) {
                            outgoingCallVideoElement.srcObject = stream;
                        }
                    });
                    call.on('close', function () {
                        try { stopOutgoingSoundRef.current.click() } catch (e) { }
                        // remoteVideoRef.current.srcObject = null;
                        // localVideoRef.current.srcObject = null;
                        if (outgoingCallVideoElement) {
                            outgoingCallVideoElement.srcObject = null;
                            outgoingCallLocalVideoElement.srcObject = null;
                        }
                        stream.getTracks().forEach(function (track) {
                            track.stop();
                        });
                        console.log("outgoing call closed!")
                        call?.off()
                        setOutgoingCalls(ps => ({ ...ps, [id]: null }))
                    });
                    call.on('error', function () {
                        try { stopOutgoingSoundRef.current.click() } catch (e) { }
                        // remoteVideoRef.current.srcObject = null;
                        // localVideoRef.current.srcObject = null;
                        if (outgoingCallVideoElement) {
                            outgoingCallVideoElement.srcObject = null;
                            outgoingCallLocalVideoElement.srcObject = null;
                        }
                        stream.getTracks().forEach(function (track) {
                            track.stop();
                        });
                        console.log("outgoing call errored!")
                        call?.off()
                        setOutgoingCalls(ps => ({ ...ps, [id]: null }))
                    });
                }).catch(err => {
                    console.log("MEDIA DEVICE USER MEDIA ERROR %O", err)
                })
        } catch (e) { console.log("💔 ERROR: F", e) }
    }

    const answerCall = (key) => {
        try { stopIncomingSoundRef.current.click() } catch (e) { }
        myStreamForAnswerCall?.getTracks().forEach(function (track) {
            track.stop();
        });

        try {
            const { video } = incomingCalls?.[key]?.metadata
            console.log("incoming metadata:", video, incomingCalls?.[key]?.metadata)
            const newConstraints = {
                ...constraints, ...(!video && {
                    video: false
                })
            }
            const incomingCallVideoElement = document.getElementById(`INCOMING_CALL_REMOTE_STREAM_${key}`)
            const incomingCallLocalVideoElement = document.getElementById(`INCOMING_CALL_LOCAL_STREAM_${key}`)
            navigator?.mediaDevices && navigator.mediaDevices.getUserMedia(newConstraints)
                .then((stream) => {
                    // setMyStreamForAnswerCall(stream)
                    setMyStreamsForIncomingCalls(ps => ({ ...ps, [key]: stream }))
                    // localVideoRef.current.srcObject = stream;
                    incomingCallLocalVideoElement.srcObject = stream;
                    incomingCalls?.[key].answer(stream)
                    incomingCalls?.[key].on('stream', stream => {
                        console.log("incoming call accepted!")
                        // remoteVideoRef.current.srcObject = stream;
                        if (incomingCallVideoElement) {
                            incomingCallVideoElement.srcObject = stream;
                        }
                    })
                    incomingCalls?.[key].on('close', () => {
                        // remoteVideoRef.current.srcObject = null;
                        // localVideoRef.current.srcObject = null;
                        if (incomingCallVideoElement) {
                            incomingCallVideoElement.srcObject = null;
                            incomingCallLocalVideoElement.srcObject = null;
                        }
                        stream.getTracks().forEach(function (track) {
                            track.stop();
                        });
                        setIncomingCalls(ps => ({ ...ps, [key]: null }))
                        console.log("incoming call closed!")
                        incomingCalls?.[key]?.off()
                    })
                    incomingCalls?.[key].on('error', () => {
                        // remoteVideoRef.current.srcObject = null;
                        // localVideoRef.current.srcObject = null;
                        if (incomingCallVideoElement) {
                            incomingCallVideoElement.srcObject = null;
                            incomingCallLocalVideoElement.srcObject = null;
                        }
                        stream.getTracks().forEach(function (track) {
                            track.stop();
                        });
                        console.log("incoming call errored")
                        incomingCalls?.[key]?.off()
                    })
                }).catch(err => {
                    console.log("MEDIA DEVICE USER MEDIA ERROR %O", err)
                })
        } catch (e) { console.log("💔 ERROR:", e) }
    }

    const dismissOutgoingCall = (id) => {
        try { stopOutgoingSoundRef.current.click() } catch (e) { }
        myCall?.close()
        setMyCall(null)
        outgoingCalls?.[id]?.close()
        setOutgoingCalls(ps => ({ ...ps, [id]: null }))
        myStreamForMakeCall?.getTracks().forEach(function (track) {
            track.stop();
        });
        myStreamsForOutgoingCalls?.[id]?.getTracks().forEach(function (track) {
            track.stop();
        });
    }

    const dismissIncomingCall = (key) => {
        try { stopIncomingSoundRef.current.click() } catch (e) { }
        incomingCalls?.[key].close()
        myStreamForAnswerCall?.getTracks().forEach(function (track) {
            track.stop();
        });
        myStreamsForIncomingCalls?.[key]?.getTracks().forEach(function (track) {
            track.stop();
        });
        delete incomingCalls?.[key]
    }

    // const toggleAudio = () => {
    //     console.log("toggle audio")
    //     if (myStreamForAnswerCall?.getAudioTracks()[0]) {
    //         myStreamForAnswerCall.getAudioTracks()[0].enabled = !myStreamForAnswerCall.getAudioTracks()[0].enabled;
    //         console.log("toggling audio")
    //     }
    //     if (myStreamForMakeCall?.getAudioTracks()[0]) {
    //         myStreamForMakeCall.getAudioTracks()[0].enabled = !myStreamForMakeCall.getAudioTracks()[0].enabled;
    //         console.log("toggling audio")
    //     }
    // }

    // const toggleVideo = () => {
    //     console.log("toggle video")
    //     if (myStreamForAnswerCall?.getVideoTracks()[0]) {
    //         myStreamForAnswerCall.getVideoTracks()[0].enabled = !myStreamForAnswerCall.getVideoTracks()[0].enabled;
    //         console.log("toggling video")
    //     }
    //     if (myStreamForMakeCall?.getVideoTracks()[0]) {
    //         myStreamForMakeCall.getVideoTracks()[0].enabled = !myStreamForMakeCall.getVideoTracks()[0].enabled;
    //         console.log("toggling video")
    //     }
    // }

    const sendFiles = (e, id) => {
        console.log("selected files for ", id, e.target.files)
        // const conn = outgoingConns?.[id]
        const conn = mypeer.connect(id, {
            metadata: {
                from: mypeer?._id,
                to: id
            }
        })
        if (conn) {
            conn.on('open', () => {
                for (const file of e.target.files) {
                    try {
                        if (file.size <= MAX_FILE_SIZE) {
                            const blob = new Blob([file], { type: file.type })
                            conn.send({
                                file: blob,
                                name: file.name,
                                type: file.type,
                            }).then(response => {
                                console.log("send success!", response)
                            }).catch(error => {
                                console.log("send error!", error)
                            })
                        } else {
                            console.log(`File size limit exceeded for ${file.name} - ${file.size}`)
                        }
                    } catch (e) {
                        console.log(e)
                    }
                }
            })
        }
    }

    // const isAudio = myStreamForAnswerCall?.getAudioTracks()?.[0].enabled || myStreamForMakeCall?.getAudioTracks()?.[0].enabled
    // const isVideo = myStreamForAnswerCall?.getVideoTracks()?.[0].enabled || myStreamForMakeCall?.getVideoTracks()?.[0].enabled

    const uploadDp = (e) => {
        setWarning("")
        console.log(e.target.files)
        // MAX FILE SIZE 500KB
        if (e.target.files?.[0] && e.target.files?.[0].size <= (256 * 1024)) {
            let imageFile = e.target.files[0];
            var reader = new FileReader();
            reader.onload = function (e) {
                setMyDp(e.target.result);
            }
            reader.readAsDataURL(imageFile);
            setWarning("")
        } else {
            setWarning("Max DP image size is 256 KB!")
        }
    }

    return (
        <Container maxWidth={"md"} sx={{ p: 1 }}>
            <Stack spacing={2}>
                <Typography align="center" variant="h4" fontFamily={"JetBrains Mono"} fontWeight={"bold"}>KNUCT BLOCKCHAIN</Typography>
                <Typography align="center" variant="h6" fontFamily={"JetBrains Mono"}>QUANTUM IMMUNE</Typography>
                <Stack justifyContent={"center"} alignItems={"center"}>
                    <Typography component={Link} variant="subtitle2" align="center" color={"info.main"} target="_blank" sx={{ px: 1 }} href="https://knuct.com" >https://knuct.com</Typography>
                </Stack>
                <Typography align="center" variant="subtitle1" fontWeight={"medium"} color={"text.disabled"}>Secure P2P Video Call & Files Transfer</Typography>

                <ListItem sx={{ border: 1, borderRadius: 2, borderColor: "divider", py: 1 }}>
                    <ListItemAvatar>
                        <Avatar src={myDp ? myDp : ""} variant="rounded" sx={{ background: "transparent" }}>
                            <DevicesIcon color="primary" />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText sx={{ wordBreak: "break-all" }} primary={myName ? myName : "Your Caller ID"} secondary={mypeer?._id ? mypeer?._id : "Refresh the page for new Caller ID"} />
                </ListItem>

                <TextField value={myName} onChange={(e) => setMyName(e.target.value)} label="Join as"
                    InputProps={{
                        endAdornment: (
                            <Stack direction={"row"}>
                                <Tooltip title="Upload DP">
                                    <IconButton size="large" color="warning" htmlFor={"UploadDP"} component='label'>
                                        <AddPhotoAlternateOutlinedIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        )
                    }}
                />

                {warning && <Alert severity="warning" sx={{ justifyContent: "center", alignItems: "center" }}>{warning}</Alert>}

                <CardActions sx={{ justifyContent: "space-around", p: 0 }}>
                    <Button sx={{ textTransform: "none" }} onClick={unPublishNickname} color="error" startIcon={<UnpublishedRoundedIcon />} fullWidth>Unpublish nickname</Button>
                    <Button sx={{ textTransform: "none" }} onClick={publishNickname} color="info" startIcon={<PublishedWithChangesRoundedIcon />} fullWidth>Publish nickname</Button>
                </CardActions>

                <input component={IconButton} id={`UploadDP`} type="file" name={`UploadDP`} accept="image/png, image/gif, image/jpeg, image/bmp" onChange={(e) => uploadDp(e)} style={{ display: "none" }} />

                <button id="playRingButton" ref={playIncomingSoundRef} onClick={() => incoming_sound.play()} style={{ display: "none" }}>play</button>
                <button id="stopRingButton" ref={stopIncomingSoundRef} onClick={() => incoming_sound.stop()} style={{ display: "none" }}>stop</button>
                <button id="playDialButton" ref={playOutgoingSoundRef} onClick={() => outgoing_sound.play()} style={{ display: "none" }}>play</button>
                <button id="stopDialButton" ref={stopOutgoingSoundRef} onClick={() => outgoing_sound.stop()} style={{ display: "none" }}>stop</button>
                <button id="playNotifyButton" ref={playNotificationSoundRef} onClick={() => notify_sound.play()} style={{ display: "none" }}>play</button>
                <button id="stopNotifyButton" ref={stopNotificationSoundRef} onClick={() => notify_sound.stop()} style={{ display: "none" }}>stop</button>

                <Grid container gap={2} justifyContent={"center"} alignItems={"center"}>
                    {/* <Grid item xs={12} sm={12} md={5} lg={5}>
                        <Card sx={{ borderRadius: 2, border: 1, borderColor: "divider", background: "#282c34" }}>
                            <CardMedia component={"video"} id="remoteVideo" sx={{ background: "#282c34" }} width={320} height={240} ref={remoteVideoRef} autoPlay />
                            <Alert severity="info" icon={false && <ReportProblemTwoToneIcon />} sx={{ justifyContent: "center", alignItems: "center", borderRadius: 0 }}>
                                Remote stream
                            </Alert>
                        </Card>
                    </Grid> */}
                    {/* <Grid item xs={12} sm={12} md={5} lg={5}>
                        <Card sx={{ borderRadius: 2, border: 1, borderColor: "divider", background: "#282c34" }}>
                            <CardMedia component={"video"} id="localVideo" sx={{ background: "#282c34", transform: 'ScaleX(-1)', WebkitTransform: "ScaleX(-1)" }} width={320} height={240} ref={localVideoRef} muted autoPlay /><Alert severity="info" icon={false && <ReportProblemTwoToneIcon />} sx={{ justifyContent: "center", alignItems: "center", borderRadius: 0 }}>
                                Local stream
                            </Alert>
                        </Card>
                    </Grid> */}
                    {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                        <CardActions sx={{ justifyContent: "space-around", border: 1, borderRadius: 2, borderColor: "divider" }}>
                            <Stack justifyContent={"center"} alignItems={"center"}>
                                <IconButton size="large" onClick={() => toggleAudio()}>{isAudio ? <MicIcon color={"info"} /> : <MicOffIcon color={"error"} />}</IconButton>
                                <Typography align="center" color={"text.secondary"} variant={"caption"}>Toggle audio</Typography>
                            </Stack>

                            <Stack justifyContent={"center"} alignItems={"center"}>
                                <IconButton size="large" onClick={() => toggleVideo()} >{isVideo ? <VideocamIcon color={"info"} /> : <VideocamOffIcon color={"error"} />}</IconButton>
                                <Typography align="center" color={"text.secondary"} variant={"caption"}>Toggle video</Typography>
                            </Stack>
                        </CardActions>
                    </Grid> */}
                </Grid>

                {Object.keys(incomingCalls).filter(key => incomingCalls?.[key]?.peer && key).length > 0 && <Typography variant="subtitle2" fontWeight={"bold"} color={"warning.main"}>INCOMING CALLS</Typography>}


                <List sx={{ overflow: "auto", scrollbarWidth: "thin", wordBreak: "break-all" }}>
                    {Object.keys(incomingCalls)
                        .filter(key => incomingCalls?.[key]?.peer && key)
                        .map(key => {
                            return (<Stack key={incomingCalls?.[key]?.connectionId} sx={{ borderRadius: 2, border: 1, borderColor: "divider", mb: 1 }}>
                                <ListItem key={incomingCalls?.[key]?.connectionId}>
                                    <ListItemAvatar>
                                        <Avatar src={nicknames?.[incomingCalls?.[key]?.peer]?.dp ? nicknames?.[incomingCalls?.[key]?.peer]?.dp : ""} variant="rounded" sx={{ background: "transparent" }}>
                                            <PhoneCallbackIcon color={"info"} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={incomingCalls?.[key]?.metadata?.nickname ? incomingCalls?.[key]?.metadata?.nickname : "Anonymous"} secondary={`${incomingCalls?.[key]?.peer} ${isIsoDateString(incomingCalls?.[key]?.metadata?.madeAt) && ` • Called ${formatDistanceToNow(new Date(incomingCalls?.[key]?.metadata?.madeAt))} ago`}`} />
                                </ListItem>
                                <Divider />
                                <Box sx={{ position: "relative", p: 0, borderBottom: 1, borderColor: "divider", ...(!document.getElementById(`INCOMING_CALL_LOCAL_STREAM_${key}`)?.srcObject && { display: "none" }), ...(!document.getElementById(`INCOMING_CALL_REMOTE_STREAM_${key}`)?.srcObject && { display: "none" }) }}>
                                    <CardMedia component={"video"} id={`INCOMING_CALL_REMOTE_STREAM_${key}`} sx={{ background: "#282c34", maxHeight: 240, }} poster={nicknames?.[incomingCalls?.[key]?.peer]?.dp ? nicknames?.[incomingCalls?.[key]?.peer]?.dp : ""} autoPlay />
                                    <Box sx={{ position: "absolute", bottom: 0, right: 0, p: 1 }}>
                                        <Card sx={{ borderRadius: 2 }}>
                                            <CardMedia component={"video"} id={`INCOMING_CALL_LOCAL_STREAM_${key}`} sx={{ background: "#282c34", maxHeight: 80, transform: 'ScaleX(-1)', WebkitTransform: "ScaleX(-1)" }} poster={nicknames?.[mypeer?._id]?.dp ? nicknames?.[mypeer?._id]?.dp : ""} autoPlay muted />
                                        </Card>
                                    </Box>
                                </Box>
                                <CardActions sx={{ justifyContent: "space-around" }}>
                                    <Grid container gap={2} justifyContent={"space-around"} alignItems={"center"}>
                                        <Grid item>
                                            <Stack justifyContent={"center"} alignItems={"center"}>
                                                <IconButton size="large" color="success" onClick={() => answerCall(key)} ><CallIcon /></IconButton>
                                                <Typography align="center" color={"text.secondary"} variant={"caption"}>Answer Call</Typography>
                                            </Stack>
                                        </Grid>

                                        <Grid item>
                                            <Stack justifyContent={"center"} alignItems={"center"}>
                                                <IconButton size="large" color="error" onClick={() => dismissIncomingCall(key)}><CallEndIcon /></IconButton>
                                                <Typography align="center" color={"text.secondary"} variant={"caption"}>End Call</Typography>
                                            </Stack>
                                        </Grid>

                                        <Grid item>
                                            <MessageArea to={incomingCalls?.[key]?.peer} conn={incomingConns?.[incomingCalls?.[key]?.peer]} nickname={nicknames?.[incomingCalls?.[key]?.peer]} />
                                        </Grid>
                                    </Grid>
                                </CardActions>
                                {/* {incomingCalls?.[key]?.metadata?.location && <Divider />}
                                {incomingCalls?.[key]?.metadata?.location && <Stack sx={{ p: 2 }}>
                                    <Typography align="center" color={"text.secondary"} variant="subtitle2" fontWeight={"light"}>{myLocation?.latitude && myLocation?.longitude && "Calling from"} {myLocation?.latitude && myLocation?.longitude && distance(incomingCalls?.[key]?.metadata?.location?.latitude, incomingCalls?.[key]?.metadata?.location?.longitude, myLocation?.latitude, myLocation?.longitude, "K").toFixed(3)} KM far.
                                    </Typography>
                                    <Typography align="center" color={"text.secondary"} variant="subtitle2" fontWeight={"light"}>Latitude: {incomingCalls?.[key]?.metadata?.location?.latitude}, Longitude: {incomingCalls?.[key]?.metadata?.location?.longitude}, </Typography>
                                </Stack>} */}
                                {liveLocations?.[incomingCalls?.[key]?.peer] && <Divider />}
                                {liveLocations?.[incomingCalls?.[key]?.peer] && <Stack sx={{ p: 2 }}>
                                    {myLocation?.latitude && myLocation?.longitude && <Typography align="center" color={"text.secondary"} variant="subtitle2" fontWeight={"light"}>Calling from {myLocation?.latitude && myLocation?.longitude && distance(liveLocations?.[incomingCalls?.[key]?.peer]?.latitude, liveLocations?.[incomingCalls?.[key]?.peer]?.longitude, myLocation?.latitude, myLocation?.longitude, "K").toFixed(3)} KM far.
                                    </Typography>}
                                    <Typography align="center" color={"text.secondary"} variant="subtitle2" fontWeight={"light"}>Latitude: {liveLocations?.[incomingCalls?.[key]?.peer]?.latitude}, Longitude: {liveLocations?.[incomingCalls?.[key]?.peer]?.longitude}, </Typography>
                                </Stack>}
                            </Stack>)
                        })}
                </List>

                {peers.filter(id => id !== mypeer?._id && id).length > 0 ? <Typography variant="subtitle2" fontWeight={"bold"} color={"success.main"}>ONLINE PEERS</Typography> : <Typography variant="subtitle2" fontWeight={"bold"} color={"error.main"} align="center">NO ONLINE PEERS</Typography>}

                <List sx={{ overflow: "auto", scrollbarWidth: "thin", wordBreak: "break-all" }}>
                    {peers
                        .filter(id => id !== mypeer?._id && id)
                        .map(id => {
                            return (<Stack key={id} sx={{ borderRadius: 2, border: 1, borderColor: myCall?.peer === id ? "primary.main" : "divider", mb: 1 }}>
                                <ListItem key={id}>
                                    <ListItemAvatar>
                                        <Avatar src={nicknames?.[id]?.dp ? nicknames?.[id]?.dp : ""} variant="rounded" sx={{ background: "transparent" }}>
                                            <DevicesIcon color={nicknames?.[id]?.nickname ? "success" : "secondary"} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={nicknames?.[id]?.nickname ? nicknames?.[id]?.nickname : "Anonymous"} secondary={id} />
                                    <Box sx={{ flexGrow: 1 }}></Box>
                                    <P2PReceivedFiles filter={{ from: id, fileCount: 0 }} />
                                </ListItem>
                                <Divider />
                                <Box sx={{ position: "relative", p: 0, borderBottom: 1, borderColor: "divider", ...(!document.getElementById(`OUTGOING_CALL_LOCAL_STREAM_${id}`)?.srcObject && { display: "none" }) }}>
                                    <CardMedia component={"video"} id={`OUTGOING_CALL_REMOTE_STREAM_${id}`} sx={{ background: "#282c34", maxHeight: 320, }} poster={nicknames?.[id]?.dp ? nicknames?.[id]?.dp : ""} autoPlay />
                                    <Box sx={{ position: "absolute", bottom: 0, right: 0, p: 1 }}>
                                        <Card sx={{ borderRadius: 2 }}>
                                            <CardMedia component={"video"} id={`OUTGOING_CALL_LOCAL_STREAM_${id}`} sx={{ background: "#282c34", maxHeight: 80, transform: 'ScaleX(-1)', WebkitTransform: "ScaleX(-1)" }} poster={nicknames?.[mypeer?._id]?.dp ? nicknames?.[mypeer?._id]?.dp : ""} autoPlay muted />
                                        </Card>
                                    </Box>
                                </Box>
                                <CardActions>
                                    <Grid container gap={2} justifyContent={"space-around"} alignItems={"center"}>
                                        <Grid item>
                                            <Stack justifyContent={"center"} alignItems={"center"}>
                                                <IconButton size="large" color="info" onClick={() => makeCall(id)}><VideoChatIcon /></IconButton>
                                                <Typography align="center" color={"text.secondary"} variant={"caption"}>Video Call</Typography>
                                            </Stack>
                                        </Grid>

                                        <Grid item>
                                            <Stack justifyContent={"center"} alignItems={"center"}>
                                                <IconButton size="large" color="success" onClick={() => makeCall(id, false)} ><CallIcon /></IconButton>
                                                <Typography align="center" color={"text.secondary"} variant={"caption"}>Voice Call</Typography>
                                            </Stack>
                                        </Grid>

                                        <Grid item>
                                            <Stack justifyContent={"center"} alignItems={"center"}>
                                                <IconButton size="large" color="error" onClick={() => dismissOutgoingCall(id)}><CallEndIcon /></IconButton>
                                                <Typography align="center" color={"text.secondary"} variant={"caption"}>End Call</Typography>
                                            </Stack>
                                        </Grid>

                                        <Grid item>
                                            <MessageArea to={id} conn={incomingConns?.[id]} nickname={nicknames?.[id]} />
                                        </Grid>

                                        <Grid item>
                                            <Stack justifyContent={"center"} alignItems={"center"}>
                                                <IconButton size="large" color="warning" htmlFor={`sendFileTo${id}`} component='label' ><UploadFileIcon /></IconButton>
                                                <Typography align="center" color={"text.secondary"} variant={"caption"}>Send Files</Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </CardActions>
                                <input component={IconButton} id={`sendFileTo${id}`} type="file" name={`sendFileTo${id}`} multiple onChange={(e) => sendFiles(e, id)} style={{ display: "none" }} />
                                {liveLocations?.[id] && <Divider />}
                                {liveLocations?.[id] && <Stack sx={{ p: 2 }}>
                                    {myLocation?.latitude && myLocation?.longitude && <Typography align="center" color={"text.secondary"} variant="subtitle2" fontWeight={"light"}>Accessing from {myLocation?.latitude && myLocation?.longitude && distance(liveLocations?.[id]?.latitude, liveLocations?.[id]?.longitude, myLocation?.latitude, myLocation?.longitude, "K").toFixed(3)} KM far.
                                    </Typography>}
                                    <Typography align="center" color={"text.secondary"} variant="subtitle2" fontWeight={"light"}>Latitude: {liveLocations?.[id]?.latitude}, Longitude: {liveLocations?.[id]?.longitude}, </Typography>
                                </Stack>}
                            </Stack>)
                        })}
                </List>

                {peers.filter(id => id !== mypeer?._id && id).length > 0 && <Typography variant="subtitle2" fontWeight={"bold"}>Live Locations of Peers</Typography>}

                <Card sx={{ borderRadius: 2, border: 1, borderColor: "divider", background: "#282c34", maxHeight: 480 }}>
                    <LiveLocationMap maxHeight={480} startLocation={[myLocation?.latitude ? myLocation?.latitude : 78.9629, myLocation?.longitude ? myLocation?.longitude : 20.5937]} />
                </Card>
            </Stack>
        </Container>
    )
}

const MessageArea = ({ to, conn, nickname }) => {
    const [messageContent, setMessageContent] = useState("")
    const [messages, setMessages] = useState([])
    const [error, setError] = useState("")
    const [outgoingConn, setOutgoingConn] = useState()
    const [connOpened, setConnOpened] = useState(false)
    const [unreadMsgs, setUnreadMsgs] = useState(false)
    const [open, setOpen] = useState(false)

    const from = mypeer?._id

    const updateScroll = () => {
        var element = document.getElementById(`${to}_CHAT_AREA`);
        if (element) { element.scrollTop = element.scrollHeight; }
    }

    const handleChatScroll = (event) => {
        return null
    }

    useEffect(() => {
        if (messages && Array.isArray(messages) && messages.length !== 0) {
            updateScroll()
        }
    }, [messages])

    useEffect(() => {
        if (to) {
            const dataConn = mypeer.connect(to)
            console.log("dataConn: ", dataConn)
            setOutgoingConn(dataConn)
            dataConn.on('open', () => {
                setConnOpened(true)
            })

            return () => {
                dataConn.off()
                setConnOpened(false)
            }
        }
    }, [to])

    useEffect(() => {
        console.log("set message data incoming conns inside useEffect...!")

        if (conn) {
            conn.on('data', data => {
                console.log('Message Received!');
                if (data?.message && data?.from && data?.to && data?.timestamp) {
                    setMessages(ps => [...ps, data])
                    setUnreadMsgs(true)
                }
            })

            return () => {
                console.log("free message data incoming conns useEffect cleanup...!")
                conn.off()
            }
        }
    }, [conn])

    const sendMessage = () => {
        console.log("message for ", to, messageContent)
        setError("")
        try {
            if (messageContent && to) {
                // const conn = outgoingConns?.[id]
                if (outgoingConn && connOpened) {
                    const data = {
                        from: mypeer?._id,
                        to: to,
                        message: messageContent,
                        timestamp: new Date().toISOString()
                    }
                    const hash = objectHash(data, { algorithm: "sha1" })
                    setMessages(ps => [...ps, { hash: hash, ...data }])
                    console.log("sending: %O", { hash: hash, ...data })
                    outgoingConn.send({ hash: hash, ...data })
                    setMessageContent("")
                } else {
                    setError(`Message Sending Failed due to outgoingConn: ${outgoingConn?.peerId} connOpened: ${connOpened}!`)
                }
            } else {
                console.log(`Message Sending Failed!`)
                setError(`Message Sending Failed due to messageContent: ${messageContent === "" ? "empty" : messageContent} to: ${to}!`)
            }
        } catch (e) {
            console.log(e)
            setError(`Message Sending Failed! ${JSON.stringify(e)}`)
        }
    }

    return (
        <Stack justifyContent={"center"} alignItems={"center"}>
            <IconButton size="large" color="secondary" onClick={() => { setOpen(true); setUnreadMsgs(false); }} >
                {unreadMsgs ?
                    <Badge color="error" variant="dot">
                        <ChatIcon />
                    </Badge>
                    : <ChatIcon />}
            </IconButton>
            <Typography align="center" color={"text.secondary"} variant={"caption"}>Chat</Typography>
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth PaperProps={{ sx: { borderRadius: 3 } }}>
                <ListItem variant="outlined" key={`${to}_PROFILE`}>
                    <ListItemAvatar>
                        <Avatar src={nickname?.dp ? nickname?.dp : ""} variant="rounded" sx={{ background: "transparent" }}>
                            <DevicesIcon color={nickname?.nickname ? "success" : "secondary"} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={nickname?.nickname ? nickname?.nickname : "Anonymous"} secondary={to} />
                    <ListItemIcon>
                        <IconButton onClick={() => setOpen(false)}>
                            <CloseIcon />
                        </IconButton>
                    </ListItemIcon>
                </ListItem>
                <Divider />
                <DialogContent sx={{ p: 2, maxHeight: window.innerHeight - 300, minHeight: window.innerHeight - 300, overflow: "auto", scrollbarWidth: "thin" }} id={`${to}_CHAT_AREA`} onScroll={handleChatScroll} >
                    <Stack spacing={1}>
                        {messages && messages.length > 0 && [...new Set(messages.map(x => JSON.stringify(x)))]
                            .map(x => JSON.parse(x))
                            .filter(x => x?.from && x?.to && x?.timestamp && x)
                            // .sort((a, b) => (a?.timestamp - b?.timestamp))
                            .sort((a, b) => (new Date(a?.timestamp).getTime() - new Date(b?.timestamp).getTime()))
                            .map((m, index) => (
                                <Stack direction={"row"} spacing={2} key={index}>
                                    {m?.from === from && <Box sx={{ flexGrow: 1, minWidth: 60 }} ></Box>}
                                    <Card className="shadow-sm" key={index} color={m?.from === from ? "primary" : "inherit"} sx={{ p: 2, textTransform: "none", borderRadius: m?.attachment ? 2 : 4, wordBreak: "break-all", ...((m?.from === from) ? { borderBottomRightRadius: 1 } : { borderBottomLeftRadius: 1 }), bgcolor: m?.from === from ? "primary.main" : "text.disabled" }}>
                                        <Stack>
                                            {m?.message && <Typography sx={{ wordBreak: 'break-all' }} variant="subtitle2" align={"left"}>
                                                {m?.message}
                                            </Typography>}
                                            <Typography variant="caption" color={"text.disabled"} align={"right"}>
                                                {format(new Date(m?.timestamp), "pp")}
                                            </Typography>
                                        </Stack>
                                    </Card>
                                    {m?.from !== from && <Box sx={{ flexGrow: 1, minWidth: 60 }} ></Box>}
                                </Stack>
                            ))}
                    </Stack>
                    {error && <Alert severity="error" sx={{ justifyContent: "center", alignItems: "center", my: 1 }} >{error}</Alert>}
                </DialogContent>
                <Divider />
                <DialogActions>
                    <TextField value={messageContent} size="small" variant="filled" placeholder="Message" hiddenLabel
                        InputProps={{
                            disableUnderline: true,
                            sx: { pl: 1, borderRadius: 3, py: 1, border: 0, borderColor: "divider", pr: 1 },
                            endAdornment: (
                                <Stack direction={"row"}>
                                    <Tooltip title="send">
                                        <IconButton size="large" color="primary" onClick={() => { sendMessage(); }}>
                                            <SendRoundedIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            )
                        }}
                        onChange={(e) => setMessageContent(e.target.value)} onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                sendMessage()
                            }
                        }} fullWidth
                    />
                </DialogActions>
            </Dialog>
        </Stack>
    )
}