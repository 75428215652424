import * as React from 'react';
import { AppBar, Box, Divider, Stack, Toolbar, Tooltip, CardMedia } from '@mui/material';
import { IconButton, Typography, LinearProgress, } from '@mui/material';
import { Link } from "react-router-dom";
import { useTheme } from '@mui/material/styles';

import AppContext from '../AppContext';
import P2PReceivedFiles from './P2PReceivedFiles';

import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';

import { ReactComponent as KunctIcon } from '../knuctIcon.svg'

export default function NavBar() {
    const theme = useTheme()
    const [appState, appStateDispatch] = React.useContext(AppContext)

    const toggleThemeClick = () => {
        appStateDispatch({ type: 'toggleTheme' })
        // closeSettingsMenu()
    }

    return (
        <Box className='navbar-wrapper' sx={{ mb: 8 }}>
            <AppBar position="fixed" color='transparent' className="navbar" sx={{ boxShadow: '0px 2px 7px 0px rgba(0,0,0,0.1)', backdropFilter: "blur(20px)" }}>
                <Toolbar sx={{ ...(false && { borderBottom: 1, borderColor: "text.disabled" }) }}>
                    <MainLogo />

                    <Box sx={{ flexGrow: 1 }} />

                    <Stack direction={"row"} spacing={0.5}>
                        <P2PReceivedFiles />
                        <Tooltip title="theme"><IconButton color={({ 'dark': undefined, 'light': "warning" }[theme.palette.mode])} onClick={toggleThemeClick}>{({ 'dark': <DarkModeOutlinedIcon />, 'light': <LightModeOutlinedIcon /> }[theme.palette.mode])}</IconButton></Tooltip>
                    </Stack>
                </Toolbar>
                <Divider />
            </AppBar>
        </Box>
    )
}

const logoAnimationStyle = {
    background: "linear-gradient(-45deg, #00F5FF, #FCE700, #FF6D28, #EA047E)",
    backgroundSize: "200% 200%",
    // WebkitBackgroundClip: "text",
    // WebkitTextFillColor: "transparent",
    animation: "gradient 30s ease infinite",
    "@keyframes gradient": {
        "0%": {
            backgroundPosition: "0% 50%",
        },
        "50%": {
            backgroundPosition: "100% 50%"
        },
        "100%": {
            backgroundPosition: "0% 50%"
        }
    }
}

function MainLogo() {
    // console.log("LOGO-LOADED!")

    return (
        <Stack>
            <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} color={"inherit"} sx={{ textDecoration: "none" }}
                component={Link} to="/"
            >
                <CardMedia sx={{ mr: 1 }}>
                    <KunctIcon style={{ width: '2em', height: '2em', verticalAlign: 'center' }} />
                </CardMedia>
                <Stack spacing={-1} justifyContent={"center"} alignItems={"flex-start"} direction={"column"}>
                    <Typography variant='caption' fontFamily={"JetBrains Mono"} sx={{ fontWeight: "bold" }}>KNUCT</Typography>
                    <Typography variant='subtitle1' fontFamily={"JetBrains Mono"} sx={{ fontWeight: "bold" }}>SECURE P2P</Typography>
                </Stack>
            </Stack>
            <LinearProgress sx={{ ...logoAnimationStyle, borderRadius: 2, height: 1.5 }} variant="determinate" value={-1} />
        </Stack>
    )
}