import React, { useState } from 'react';
import { Box, Dialog, Button, DialogContent, Stack, Typography, Divider } from '@mui/material';
import { ListItemButton, ListItemAvatar, ListItemText, Avatar, Link } from '@mui/material';
import { CardMedia, CardActions } from '@mui/material';

import { format } from 'date-fns';
import { formatBytes } from '../utils/common';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DataObjectIcon from '@mui/icons-material/DataObject';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';

export default function FilePreview({ file }) {
    const mimeType = file.type.split("/")[0]
    const previewIcon = mimeType.includes("video") ?
        <VideoFileIcon color="primary" /> :
        mimeType.includes("audio") ?
            <AudioFileIcon color="secondary" /> :
            mimeType.includes("pdf") ?
                <PictureAsPdfIcon color="error" /> :
                mimeType.includes("text") ?
                    <TextSnippetIcon color="info" /> :
                    mimeType.includes("json") ?
                        <DataObjectIcon color="warning" /> :
                        <InsertDriveFileIcon />

    const [openPreview, setOpenPreview] = useState(false)

    const media = mimeType.includes("image") ? "img" :
        mimeType.includes("video") ? "video" :
            mimeType.includes("audio") ? "audio" : "object"

    return (
        <Box sx={{ wordBreak: "break-all" }}>
            <Dialog open={openPreview} onClose={() => setOpenPreview(false)} PaperProps={{ sx: { borderRadius: 3 } }}>
                <DialogContent sx={{ maxHeight: 400, overflow: "auto", p: 0, scrollbarWidth: "thin" }}>
                    <CardMedia component={media} sx={{ ...(["video", "audio"].includes(media) && { maxHeight: 240 }), background: "black" }} src={file?.preview} controls={["video", "audio"].includes(media)} />
                </DialogContent>
                <ListItemText
                    sx={{
                        borderTop: 1, borderBottom: 1, borderColor: "divider", p: 2, wordBreak: "break-all", my: 0
                    }} primary={file.name} secondary={`size: ${formatBytes(file.size)} • last modified: ${format(new Date(file.lastModified), "Pp")}`} />
                <Stack sx={{ wordBreak: "break-all" }} spacing={1}>
                    {file?.from && <Stack sx={{ px: 1, pt: 1 }} direction={"row"} spacing={1} alignItems={"center"} justifyContent={"center"}>
                        <ArrowCircleDownIcon />
                        <Typography variant='subtitle2' fontFamily={"JetBrains Mono"} color={"text.secondary"}>{`${file?.from}`}</Typography>
                    </Stack>}
                    {file?.to && <Stack sx={{ px: 1, pb: 1 }} direction={"row"} spacing={1} alignItems={"center"} justifyContent={"center"}>
                        <ArrowCircleUpIcon />
                        <Typography variant='subtitle2' fontFamily={"JetBrains Mono"} color={"text.secondary"}>{`${file?.to}`}</Typography>
                    </Stack>}
                </Stack>
                {file?.from && file?.to && <Divider />}
                <CardActions>
                    <Button component={Link} href={file?.preview} download={file?.name} color='info' sx={{ p: 2 }} fullWidth>Download</Button>
                    <Button onClick={() => setOpenPreview(false)} color='error' sx={{ p: 2 }} fullWidth>CLOSE</Button>
                </CardActions>
            </Dialog>
            <ListItemButton onClick={() => setOpenPreview(true)}
                divider
            // sx={{ border: 1, borderRadius: 2, borderColor: "divider" }}
            >
                <ListItemAvatar >
                    <Avatar
                        src={file?.preview}
                        alt={'❌'}
                        variant="rounded"
                        sx={{ background: "transparent" }}
                    >
                        {previewIcon}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={file.name} secondary={`size: ${formatBytes(file.size)} • last modified: ${format(new Date(file.lastModified), "Pp")} `} />
            </ListItemButton>
        </Box>

    )
}