import Peer from "peerjs";

const peerConfig = {
    host: process.env.REACT_APP_PEERJS_HOST,
    key: process.env.REACT_APP_PEERJS_KEY,
    path: process.env.REACT_APP_PEERJS_PATH,
    debug: 1,
    secure: true,
    port: 443,
    ...(window.location.protocol === 'https:' && { port: 443, secure: true })
}

export const mypeer = new Peer(peerConfig)

mypeer.on('disconnected', () => {
    if (mypeer.disconnected && !mypeer.destroyed) {
        mypeer.reconnect()
        console.log("🔵 PEER RECONNECTING...")
    }
})

mypeer.on('error', err => {
    switch (err?.type) {
        case 'browser-incompatible':
            console.log('browser-incompatible')
            break;
        case 'disconnected':
            console.log('disconnected')
            if (mypeer.disconnected && !mypeer.destroyed) {
                mypeer.reconnect()
                console.log("🔵 PEER RECONNECTING...")
            }
            break;
        case 'invalid-id':
            console.log('invalid-id')
            break;
        case 'invalid-key':
            console.log('invalid-key')
            break;
        case 'network':
            console.log('network')
            break;
        case 'peer-unavailable':
            console.log('peer-unavailable')
            break;
        case 'server-error':
            console.log('server-error')
            break;
        case 'socket-error':
            console.log('socket-error')
            break;
        case 'socket-closed':
            console.log('socket-closed')
            break;
        default:
            console.log(err)
    }
})