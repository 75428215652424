import { createContext, useReducer } from "react";

const AppContext = createContext();

function reducer(prevState, action) {
    switch (action.type) {
        case 'toggleTheme':
            let newTheme = prevState.theme === 'light' ? 'dark' : 'light'
            store('theme', newTheme)
            return { ...prevState, theme: newTheme }
        default:
            return prevState

    }
}

const initialArg = null

function init() {
    let state = {
        theme: 'dark',
    }

    // access data stored in local storage
    const storedItems = ['theme']
    for (const item of storedItems) {
        let value = getStored(item)
        if (value)
            state[item] = value
    }

    return state
}

function AppContextProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialArg, init)

    return (
        <AppContext.Provider value={[state, dispatch]}>
            {props.children}
        </AppContext.Provider>
    );
}

export default AppContext;
export { AppContextProvider };

function store(name, value) {
    if (window.localStorage) {
        try {
            window.localStorage.setItem(name, JSON.stringify(value))
        } catch { }
    }
}

function getStored(name) {
    if (window.localStorage) {
        try {
            return JSON.parse(window.localStorage.getItem(name))
        } catch { }
    }
    return null
}

function removeStored(items) {
    if (window.localStorage) {
        for (const item of items) {
            try {
                window.localStorage.removeItem(item)
            } catch { }
        }
    }
}