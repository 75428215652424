import * as React from 'react';
import { Box, CircularProgress, IconButton, useTheme, useMediaQuery, Stack, Typography } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { AppContextProvider } from './AppContext';
import CustomThemeProvider, { baseTheme } from './CustomTheme';

import './App.css';

import NavBar from './components/NavBar';
import VideoCall from './components/VideoCall';
import PageNotFound from './pages/PageNotFound';

function App() {

    return (
        <AppContextProvider>
            <CustomThemeProvider>
                <Router basename={process.env.PUBLIC_URL}>
                    <NavBar />
                    <Box sx={{ minHeight: "calc(100vh - 180px)", flexGrow: 1, py: 4, px: { xs: 1, sm: 2, md: 3 } }} className='content-wrapper'>
                        <React.Suspense fallback={<SuspenseFallback />}>
                            <VideoCall />
                        </React.Suspense>
                    </Box>

                </Router>
            </CustomThemeProvider>
        </AppContextProvider>
    );
}

function MainRoutes() {
    return (
        <Routes>
            <Route path="/videocall" element={<VideoCall />} />
            <Route path="/" element={<VideoCall />} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    )
}


const SuspenseFallback = () => {

    return (
        <Stack spacing={2} sx={{ p: 4 }} justifyContent={"center"} alignItems={"center"}>
            <CircularProgress sx={{ color: "text.disabled" }} />
            <Typography variant='subtitle1' fontWeight={"light"}> Loading...</Typography>
        </Stack>
    )
}

export default App;